:root {
    --color-primary: #2584ff;
    --color-secondary: #00d9ff;
    --color-accent: #ff3400;
    --color-headings: #1b0760;
    --color-body: #918ca4;
    --color-body-darker: #5c5577;
    --color-border: #ccc;
    --color-nav: #000;
    --color-background-dark: linear-gradient(to right, #5221E4, #70FAE6);
    --color-background-dark1: linear-gradient(to left, #5221E4, #70FAE6);
    --color-background-dark2: radial-gradient(circle at center, #5221E4, #70FAE6);
    --color-backgrounddark-body: white;
    --border-radius: 30px;
}

*, 
*::after, 
*::before {
    box-sizing: border-box;
}

/* Typography */

::selection {
    background: var(--color-primary);
    color: #fff;
}

html{
    /* 62.5% of 16px = 10px */ 
    font-size: 62.5%;
    width: 100%;
    scroll-behavior: smooth;
}

body {
    font-family: Inter, Arial, Helvetica, sans-serif;
    font-size: 2rem;
    line-height: 1.5;
    columns: var(--color-body);
}

h1, 
h2, 
h3 {
    color: var(--color-headings);
    margin-bottom: 1rem;
    line-height: 1.1;
}

h1 {
    /* 1rem = 1x the size of the same html element, in this case, font size */
    font-size: 4rem;
}

h2 {
    font-size: 3rem;
}

h3 {
    font-size: 2.5rem;
    font-weight: 500;
}

p {
    margin-top: 0;
}

@media screen and (min-width: 1024px) {
    body {
        font-size: 1.8rem;
    }

    h1 {
        font-size: 8rem;
    }

    h2 {
        font-size: 4rem;
    }

    h3 {
        font-size: 2.4rem;
    }
}

/* Links */

a {
    text-decoration: none;
}

.link-arrow {
    color: var(--color-accent);
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: bold;
}

.link-arrow::after {
    content: '-->';
    margin-left: 5px;
    transition: margin 0.15s;
}

.link-arrow:hover::after {
    margin-left: 10px;
}

@media screen and (min-width: 1024px) {
    .link-arrow {
        font-size: 1.5rem;
    }
}

/* Badges */

.badge {
    border-radius: 20px;
    font-size: 2rem;
    font-weight: 600;
    padding: 0.5rem 2rem;
    white-space: nowrap;
}

.badge--primary {
    background: var(--color-primary);
    color: #fff;
}

.badge--secondary {
    background-color: var(--color-secondary);
    color: #fff;
}

.badge--small {
    font-size: 1.6rem;
    padding: 0.5rem 1.5rem;
}

@media screen and (min-width: 1024px) {
    .badge {
        font-size: 1.5rem;
    }

    .badge--small {
        font-size: 1.2rem;
    }
}

/* Lists */

.list {
    list-style: none;
    padding-left: 0;
}

.list--inline .list__item {
    display: inline-block;
    margin-right: 2rem;
}

.list--tick {
    list-style-image: url(/images/tick.svg);
    padding-left: 3rem;
    color: var(--color-headings);
}

.list--tick .list__item {
    padding-left: 0.5rem;
    margin-bottom: 1rem;
}

@media screen and (min-width: 1024px) {
    .list--tick .list__item {
        padding-left: 0;
    }
}


/* Icons */

.icon {
    width: 40px;
    height: 40px;
}

.icon--small {
    width: 30px;
    height: 30px;
}

.icon--primary {
    fill: var(--color-primary);
}

.icon--accent {
    fill: var(--color-accent);
}

.icon--white {
    fill: #fff;
}

.icon-container{
    background: #f3f9fa;
    width: 64px;
    height: 64px;
    border-radius: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.icon-container--accent {
    background: var(--color-accent);
}

/* Buttons */

.btn {
    border-radius: 40px;
    border: 0;
    cursor: pointer;
    font-size: 1.8rem;
    font-weight: 600;
    margin: 1rem 0;
    outline: 0;
    padding: 2rem 4vw;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
}

    /* BTN-LANGUAGE AND BTN-LANGUAGE:HOVER ADDED BY ME */
.btn--language {
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    background-size: cover;
    border: none;
    border-radius: 50%;
}

.btn--language__flagpt {
    border-radius: 50%;
    background-image: url("/images/pt.png");
    border: none;
    background-color: #000;
}

.btn--language__flaggb {
    background-image: url("/images/gb.png");
    border: none;
    background-color: #000;
}

.btn--language:hover {
    transform: scale(1.2);
}
    /* END OF MY INPUT */

.btn .icon {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    vertical-align: middle;
}

.btn--primary {
    background: var(--color-primary);
    color: #fff;
}

.btn--primary:hover{
    background: #3a8ffd;
}

.btn--secondary {
    background: var(--color-secondary);
    color: #fff;
}

.btn--secondary:hover{
    background: #00c8eb;
}

.btn--accent {
    background: var(--color-accent);
    color: #fff;
}

.btn--accent:hover {
    background: #ec3000;
}

.btn--outline {
    background: #fff;
    color: var(--color-headings);
    border: 2px solid var(--color-headings);
}

.btn--stretched {
    padding-left: 6rem;
    padding-right: 6rem;
}

.btn--outline:hover {
    background: var(--color-headings);
    color: #fff;
}

.btn--block {
    width: 100%;
    display: inline-block;
}

@media screen and (min-width: 1024px) {
    .btn {
        font-size: 1.5rem;
    }

        /* BTN-LANGUAGE AND BTN-LANGUAGE:HOVER ADDED BY ME */
.btn--language {
    width: 3rem;
    height: 3rem;
    background-color: var(--color-headings);
    color: #fff;
}
}

/* Inputs */

.input {
    border-radius: var(--border-radius);
    border: 1px solid var(--color-border);
    color: var(--color-headings);
    font-size: 2rem;
    outline: 0;
    padding: 1.5rem 3.5rem;
}

::placeholder {
    color: #cdcbd7;
}

.input-group {
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius);
    display: flex;
}

.input-group .input {
    border: 0;
    flex-grow: 1;
    padding: 1.5rem 2rem;
    width: 0;
}

.input-group .btn {
    margin: 4px;
}

@media screen and (min-width: 1024px) {
    .input {
        font-size: 1.5rem;
    }
}

/* Cards */

.card {
    border-radius: 7px;
    box-shadow: 0 0 20px 10px #f3f3f3;
    overflow: hidden;
}

.card__header, 
.card__body {
    padding: 2rem 3rem;
}

.card--primary .card__header {
    background: var(--color-background-dark);
    font-weight: 700;
    color: #fff;
}

.card--secondary .card__header {
    background: var(--color-background-dark2);
    color: #fff;
}
.card--primary--right .card__header {
    background: var(--color-background-dark1);
    font-weight: 700;
    color: #fff;
}

.card--primary .badge--primary {
    background: #126de4;
}


.card--secondary .badge--secondary {
    background-color: #02cdf1;
}

/* Plans */

.plan {
    transition: transform .2s ease-out;
}

.plan__name {
    color: #fff;
    margin: 0;
    font-weight: 500;
    font-size: 2.4rem;
}

.plan__name--services {
    color: #fff;
    margin: 0;
    font-weight: 500;
    font-size: 3rem;
    text-align: center;
}


.plan__price {
    font-size: 6rem;
}

.plan__billing-cycle {
    font-size: 2.4rem;
    font-weight: 300;
    opacity: 0.8;
    margin-right: 1rem;
}

.plan__description {
    font-size: 2rem;
    font-weight: 300;
    letter-spacing: 1px;
    display: block;
}

.plan .list__item {
    margin-bottom: 2rem;
}

.plan--popular {
    transform: scale(1.1);
}

.plan--popular .card__header {
    position: relative;
}

.plan--popular .card__header::before {
    content: url(/images/popular.svg);
    width: 40px;
    display: inline-block;
    position: absolute;
    top: -6px;
    right: 5%;
}

.plan:hover {
    transform: scale(1.05);
}

.plan--popular:hover {
    transform: scale(1.15);
}


@media screen and (min-width: 1024px) {
    .plan__name {
        font-size: 1.4rem;
    }

    .plan__price {
        font-size: 5rem;
    }

    .plan__billing-cycle {
        font-size: 1.6rem;
    }

    .plan__description {
        font-size: 1.7rem;
    }
}

/* Media */

.media {
    display: flex;
    margin-bottom: 4rem;
}

.media__title {
    margin-top: 0;
}
.media__title__accent {
    margin-top: 0;
    color: var(--color-headings);
}

.media__body {
    margin: 0 2rem;
    padding-bottom: 1rem;
}

.media__image {
    margin-top: 1rem;
}

/* Quotes */

.quote {
    font-size: 2rem;
    text-align: left;
    font-style: italic;
    color: var(--color-body-darker);
    line-height: 1.3;
    word-wrap: break-word;
    overflow-wrap: break-word;
    margin: 0;
    margin-bottom: 3rem;
}

.quote__text::before {
    content: open-quote;
}

.quote__text::after {
    content: close-quote;
}

.quote__author {
    font-size: 3rem;
    font-weight: 500;
    font-style: normal;
    margin-bottom: 0;
}

.quote__organisation {
    color: var(--color-headings);
    opacity: 0.4;
    font-size: 2rem;
    font-style: normal;
}

.quote__line {
    position: relative;
    bottom: 10px;
}

@media screen and (min-width: 1024px) {
    .quote {
        font-size: 2rem;
    }

    .quote__author {
        font-size: 2.4rem;
    }

    .quote__organisation {
        font-size: 1.6rem;
    }
}

/* Grids */

.grid {
    display: grid;
    align-items: center;
}

@media screen and (min-width: 768px) {
    .grid--1x2 {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (min-width: 1024px) {
    .grid--1x3 {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

/* Testimonials */

.testimonial {
    padding: 1rem;
}

.testimonial__image {
    position: relative;
    background-color: white; /* I ADDED THIS */
}


.testimonial__image > img {
    width: 90%;
}

.testimonial__image > .icon-container {
    position: absolute;
    top: 3rem;
    right: -32px;
}

@media screen and (min-width: 768px) {
    .testimonial .quote,
    .testimonial .quote__author {
        font-size: 2.4rem;
    }

    /* I ADDED THIS */
    .testimonial__image > img { 
        margin-bottom: 4rem;
        width: 100%;
    }

    .testimonial .quote {
        margin-left: 6rem;
    }
}

/* Callout Card */

.callout {
    padding: 4rem;
    border-radius: 5px;
}

.callout--primary{
    background: var(--color-primary);
    color: #fff;
}

.callout__heading {
    color: #fff;
    margin-top: 0;
    font-size: 3rem;
}

.callout .btn {
    justify-self: center;
    align-self: center;
}

.callout__content {
    text-align: center;
}

@media screen and (min-width: 768px) {
    .callout {
        width: 60%;
        position: relative;
        left: 30%;
        transform: translateX(-50%);
        margin: 0 auto;
        margin-top: -12rem;
        padding: 2rem;
        border-radius: 25px;
    }

    .callout .grid--1x2 {
        grid-template-columns: 1fr auto;
    }

    .callout__content {
        text-align: left;
    }

    .callout .btn {
        justify-self: start;
        margin: 0 2rem;
    }
}

/* Collapsibles */

.collapsible__header {
    display: flex;
    justify-content: space-between;
}

.collapsible__heading {
    margin-top: 0;
    font-size: 3rem;
}

.collapsible__chevron {
    transform: rotate(-90deg);  
}

.collapsible__content {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: all 0.3s ;
}

.collapsible--expanded .collapsible__chevron {
    transform: rotate(0);
}

.collapsible--expanded .collapsible__content {
    max-height: 250vh;
    opacity: 1;
}

@media screen and (min-width: 768px) {
    .collapsible__content {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        transition: all 0.3s ;
    }
}

/* Blocks */

.block {
    --padding-vertical: 4rem;
    padding: var(--padding-vertical) 1rem;
}
@media screen and (min-width: 768px) {
    .block {
        --padding-vertical: 6rem;
        padding: var(--padding-vertical) 2rem;
    }
}

.block__header {
    text-align: center;
    margin-bottom: 4rem;
}

.block__header--apptopia {
    text-align: center;
    margin-bottom: 1rem;
    padding-bottom: 2rem;
}

.block__heading {
    margin-top: 0;
    word-wrap: break-word;
    overflow-wrap: break-word;
    /* word-break: break-all; */
}

.block--dark {
    background: var(--color-background-dark);
    color: #7b858b;
}
.block--dark1 {
    background: var(--color-background-dark1);
    color: var(--color-backgrounddark-body);
}

.block--dark h1,
.block--dark h2,
.block--dark h3 {
    color: #fff;
}

.block--skewed-right {
    padding-bottom: calc(var(--padding-vertical) + 4rem);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 90%);
}

.block--skewed-left {
    padding-bottom: calc(var(--padding-vertical) + 4rem);
    clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0% 100%);
}

.container {
    max-width: 1140px;
    margin: 0 auto;
}

/* Navigation Bar */

.nav {
    background: var(--color-nav);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 1rem;
    align-items: center;
    position: sticky; /* This makes it sticky */
    top: 0; /* This sets it to stick at the top */
    z-index: 1000; /* This ensures it appears above other content */
}

.nav__list {
    width: 100%;
    margin: 0;
}

.nav__item {
    padding: 0.5rem 2rem;
    border-bottom: 1px solid #222;
}

.nav__item > a {
    color: #d2d0db;
    transition: color 0.3s;
}

.nav__item > a:hover {
    color: #ffff;
}

.nav__toggler {
    padding-top: 0.5rem;
    opacity: 0.5;
    transition: box-shadow 0.5s;
    cursor: pointer;
}

.nav.collapsible--expanded .nav__toggler {
    opacity: 1;
    box-shadow: 0 0 0 3px #666;
    border-radius: 5px;
}

.nav__brand {
    transform: translateY(5px);
}

.nav__item .btn--language {
    /* position: flex; */
    object-position: top;
    transform: scale(1.5);
}

@media screen and (min-width: 768px) {
    .nav__toggler {
        display: none;        
    }

    .nav__list {
        width: auto;
        display: flex;
        font-size: 1.6rem;
        max-height: 100%;
        opacity: 1;
    }

    .nav__item {
        border: 0;
    }
}

/* Banner / Hero */

.banner__tagline {
    font-size: 2rem;
    color: #fff;
    letter-spacing: 1px;
    margin-top: 2rem;
    margin-bottom: 5rem;
}

.banner__image {
    width: 100%;
}

@media screen and (min-width: 768px) {
    .banner {
        padding-top: 0;
    }
    
    .banner__content {
        text-align: left;
        align-self: center;
    }

    .banner__image {
        width: 100%;
    }


}


/* Domain Block */

.block-domain .input-group {
    box-shadow: 0 0 30px 20px #e6ebee;
    border: 0;
    margin: 4rem auto;
    max-width: 670px;
}

.block-domain__prices {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 6rem);
    font-size: 2rem;
    font-weight: 600;
    justify-items: center;
    max-width: 700px;
    margin: 0 auto;
}

@media screen and (min-width: 768px) {
    .block-domain__prices {
        grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    }
}

/* Plans Block */

.block-plans .grid {
    gap: 8rem 4rem;
}

.block-plans .card {
    max-width: 500px;
    margin: 0 auto;
}

/* Features */

.feature {
    gap: 4rem 2rem;
    margin: 12rem 0;
}

.feature--services {
    gap: 4rem 2rem;
    margin: 6rem 0;
}

.feature:first-of-type {
    margin-top: 6rem;
}

.feature--services:first-of-type {
    margin-top: 6rem;
}

.feature__heading {
    margin: 1rem 0;
}

.feature__image {
    width: 100%;
}

.feature__contactus {
    text-align: center;
    width: 100%;
}



@media screen and (min-width: 768px) {
    .feature:nth-of-type(even) .feature__content {
        order: 2;
    }
    .feature--services:nth-of-type(even) .feature__content {
        order: 2;
    }
    .feature__image {
        width: 100%;
        max-height: 400px;  /* Set to desired max height */
        object-fit: contain; /* Covers the set dimension without stretching */
    }

    .feature__contactus {
        transform: scale(1.2);
    }
}

/* Showcase Block */

.block-showcase__image > img {
    width: 100%;
}

@media screen and (min-width: 768px) {
    .block-showcase .grid {
        grid-template-columns: 50% 50%;
    }
    .block-showcase__image {
        justify-self: end;
        padding-top: 8rem; /* I ADDED THIS */
    }

    .block-showcase__image > img {
        width: auto;
        max-width: 700px;
    }
}

/* Footer */

.footer {
    background: var(--color-nav);
}

.footer a {
    color: #777;
    transition: color 0.3s;
}

.footer a:hover {
    color: #fff;
}

.footer__section {
    padding: 2rem;
    border-bottom: 1px solid #393939;
}

.footer__section .list {
    margin: 0;
}

.footer__heading {
    text-transform: uppercase;
    font-weight: 600;
}

.footer__brand {
    margin-top: 5rem;
    text-align: center;
}

.footer__brand img {
    width: 100%;
    max-width: 230px;
    max-height: 120px;
}

.footer__copyright {
    font-size: 2.1rem;
    color: #fff;
    opacity: .3;
}

@media screen and (min-width: 768px) {
    .footer__sections {
        grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    }

    .footer .collapsible__chevron {
        display: none;
    }

    .footer .collapsible__content {
        opacity: 1;
        max-height: 100%;
    }

    .footer__brand {
        order: -1;
        margin-top: 1rem;
    }

    .footer__copyright{
        font-size: 1.5rem;
    }

    .footer__section {
        border: 0;
    }

    .footer__heading {
        font-size: 1.6rem;
    }
}


/* CONTACT FORM */

.contact--form {
    display: flex;
    flex-direction: column;
    /* align-items: right; */
}

.block__contact--form {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.contact--form__label {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    max-width: 100%;
}

.contact--form__input[type="submit"] {
    margin-top: 2rem;
}

.contact--form__fields {
    border-radius: var(--border-radius);
    border: 1px solid var(--color-border);
    color: var(--color-headings);
    font-size: 2rem;
    outline: 0;
    padding: 0.5rem 1.5rem;
}

.contact--form__fields:focus {
    border-color: #1b0760;
}

.contactus__image {
    flex: content;
    max-width: 100% ;
}

.locations__image:hover {
    transform: scale(1.2);
}

.container--contacts {
    padding-top: 10rem;
    align-items: center;
}

@media screen and (min-width: 768px) {
    .locations__image .grid {
        grid-template-columns: 50% 50%;
    }
    .locations__image {
        justify-self: end;
    }

    .locations__image > img {
        width:100%;
        max-width: 700px;
    }

    .contact--form__fields {
        border-radius: var(--border-radius);
        border: 1px solid var(--color-border);
        color: var(--color-headings);
        font-size: 2rem;
        outline: 0;
        padding: 1.5rem 3.5rem;
    }

    .contact--form__fields:focus {
        border-color: #1b0760;
        transform: scale(1.2);
    }

    .block__contact--form {
        padding-left: 10rem;
        padding-right: 10rem;
    }
}

/* ABOUT US */

.aboutus__tagline {
    font-size: 2rem;
    text-align: left;
    color: var(--color-backgrounddark-body);
    letter-spacing: 1px;
    margin-top: 2rem;
    margin-bottom: 5rem;
}

.ourculture__image_right {
    box-shadow: 2rem 2rem 2rem  rgba(0, 0, 0, 0.2);
    width: 60%;
    float: right;
}

.ourculture__image_left {
    box-shadow: -2rem 2rem 2rem  rgba(0, 0, 0, 0.2);
    width: 60%;
    float: left;
}

.ourculture__zoomright {
    transition: transform 0.4s ease-in-out;
}

.ourculture__zoomleft {
    transition: transform 0.4s ease-in-out;
}


.ourculture__zoomright:hover{
    transform: translate(40%, 10%) scale(1.3);
    background-color: #fff;
}

.ourculture__zoomleft:hover{
    transform: translate(-40%, 10%) scale(1.3);
    background-color: #fff;
}

/* SERVICES */

/* Expandible boxes */

.box--heading {
    font-size: 3rem;
    text-align: center;
}

.feature--services {
    gap: 4rem 2rem;
    margin: 1rem 0;
}

.card__body--services {
    padding: 2rem 3rem;
    text-align: center;
    width: 100%;
}

.chevron__background:hover {
    background-color: var(--color-secondary);
}

@media screen and (min-width: 768px) {
    .chevron__background:hover {
        background-color: var(--color-secondary);
        transform: scale(1.1);
        transition: transform 0.3s ease-in;
        border-radius: 5px;
    }
}

.map--style {
    max-width: 320px;
    height: 340px;
}

@media screen and (min-width: 768px) {
    .map--style {
        min-width: 305px;
        max-width: 500px;
        height: 340px;
    }
}